import React, { Component } from "react";

import { GridElement, Icon } from "../base_components";
import BatteryIcon from "./battery_icon";
import SemaphoreIcon from "./semaphore_icon";
import SirenIcon from "./siren_icon";

import colors from "../../values/colors";
import { command_value } from "../../values/commands";
import { getSensorStatus } from "../../utils/sensor_utils";
import { isObject } from "../../utils/utils";
import { SensorStatus } from "../../values/statuses";
import urls from "../../values/urls";

class SensorPreview extends Component {
  constructor(props) {
    super(props);

    if (!isObject(props.sensor))
      throw new TypeError('"sensor" must be an object and passed as prop');

    this.alarm_timeout = null;

    this.state = {
      mounted: false,
      alarm: false,
      status_verbose: "",
    };
  }

  customSetState(state) {
    if (this.state.mounted) {
      this.setState(state);
    }
  }

  componentDidMount() {
    this.setState({ mounted: true });

    // calculate sensor status every second
    this.interval = setInterval(() => {
      this.customSetState({ status_verbose: this.getStatusVerbose() });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);

    this.setState({ mounted: false });
  }

  getId() {
    const { sensor } = this.props;
    return `sensor-preview-${sensor.id}`;
  }

  getStatusVerbose() {
    if (this.state.alarm) return SensorStatus.ALARM;

    const { sensor } = this.props;
    const status = getSensorStatus(sensor);

    // calculate again sensor status in 5 minutes (5 minutes - time_diff)
    if (
      status === SensorStatus.ALARM ||
      status === SensorStatus.ALARM_DISABLED
    ) {
      clearTimeout(this.alarm_timeout);
      this.alarm_timeout = setTimeout(() => {
        this.forceUpdate();
      }, 1000 * 60 * 5 - Date.now() + new Date(sensor.alarm_status.insert_date).getTime());
    }

    return status;
  }

  getClassName() {
    const { status_verbose } = this.state;

    let class_name = "";

    if (
      status_verbose === SensorStatus.ALARM ||
      status_verbose === SensorStatus.ALARM_DISABLED
    ) {
      class_name = "blink";
    }

    return class_name;
  }

  getBackgroundUrl() {
    const { sensor } = this.props;

    if (!isObject(sensor)) return null;

    const last_alarm_date = sensor.alarm_status.insert_date || null;
    const last_periodic_date = sensor.periodic_status.insert_date || null;

    let prop;
    if (!!last_alarm_date && !!last_periodic_date) {
      // choose one of them by date
      const date_alarm = new Date(last_alarm_date).getTime();
      const date_periodic = new Date(last_periodic_date).getTime();

      prop = date_alarm <= date_periodic ? "alarm_status" : "periodic_status";
    } else {
      prop = last_alarm_date
        ? "alarm_status"
        : last_periodic_date
        ? "periodic_status"
        : null;
    }

    // neither alarm nor periodic status are set
    if (!prop || !sensor[prop].image) return null;

    return sensor[prop].image.url;
  }

  renderBatteryIcon() {
    const { battery_status = "", v_12v } = this.props.sensor.last_status;

    return (
      <BatteryIcon
        batteryStatus={battery_status}
        voltage={v_12v}
        style={{ ..._style.icon, ..._style.iconBattery }}
      />
    );
  }

  renderBottom() {
    const { status_verbose } = this.state;
    let status_name = status_verbose;
    if (status_verbose === SensorStatus.ALARM_DISABLED) {
      status_name = "alarm";
    }

    return (
      <div className="sensor-preview-bottom" style={_style.bottomContainer}>
        <span>{status_name}</span>
        {this.renderBatteryIcon()}
      </div>
    );
  }

  renderSemaphoreIcon() {
    let { disable_sem, web_sem } = this.props.sensor.last_status;

    if (web_sem == command_value.UNKNOWN) {
      disable_sem = true;
    }

    return (
      <SemaphoreIcon disabled={disable_sem} web={web_sem} style={_style.icon} />
    );
  }

  renderSirenIcon() {
    let { disable_sir, web_sir } = this.props.sensor.last_status;

    if (web_sir == command_value.UNKNOWN) {
      disable_sir = true;
    }

    return (
      <SirenIcon disabled={disable_sir} web={web_sir} style={_style.icon} />
    );
  }

  renderCameraIcon() {
    const { camera_off } = this.props.sensor.last_status;

    if (!camera_off) return null;

    return <Icon name="camera_off" />;
  }

  renderIcons() {
    const cameraIcon = this.renderCameraIcon();
    const semaphoreIcon = this.renderSemaphoreIcon();
    const sirenIcon = this.renderSirenIcon();

    return (
      <div style={_style.icons.container}>
        {cameraIcon && <div style={_style.icons.group}>{cameraIcon}</div>}
        {(semaphoreIcon || sirenIcon) && (
          <div style={_style.icons.group}>
            {semaphoreIcon}
            {sirenIcon}
          </div>
        )}
      </div>
    );
  }

  render() {
    const { sensor, className, showOneSensorPerRow } = this.props;
    const { status_verbose } = this.state;
    // const status_verbose = SensorStatus.ALARM;

    // log('Rendering sensor preview. Sensor: ', sensor);

    const showOneSensorPerRowClass = showOneSensorPerRow ? "one-per-row" : "";

    let style_name = status_verbose;

    return (
      <GridElement
        id={this.getId()}
        className={`sensor-preview ${showOneSensorPerRowClass} ${
          className || ""
        } ${this.getClassName()}`}
        title={sensor.name}
        bottom={this.renderBottom()}
        bottomStyle={{ ..._style.bottom.default, ..._style.bottom[style_name] }}
        href={`${urls.DASHBOARD_BASE}/sensors/${sensor.id}`}
        backgroundUrl={this.getBackgroundUrl()}
        overlay={"rgba(255, 255, 255, .2)"}
        style={{ ..._style.default, ..._style[style_name] }}
      >
        {/*<div className="sensor-preview-icons" style={_style.iconsContainer}>*/}
        {/*{this.renderBatteryIcon()}*/}
        {/*</div>*/}
        {this.renderIcons()}
      </GridElement>
    );
  }
}

const _style = {
  default: {
    color: "#fff",
    borderWidth: "2.5px",
    // backgroundColor: '#ffffff20',
    borderColor: colors.disabled,
  },
  [SensorStatus.ONLINE]: {
    borderColor: colors.success,
    // backgroundImage: `linear-gradient(to bottom, ${colors.success}95, ${colors.successLight}, ${colors.successTransparent})`,
  },
  [SensorStatus.OFFLINE]: {
    borderColor: colors.disabled,
    // backgroundImage: `linear-gradient(to bottom, ${colors.disabled}95, ${colors.disabledLight}, ${colors.disabledTransparent})`,
  },
  [SensorStatus.SLEEP]: {
    borderColor: colors.primaryLight,
    // backgroundImage: `linear-gradient(to bottom, ${colors.secondary}95, ${colors.secondaryLight}, ${colors.secondaryTransparent})`,
  },
  [SensorStatus.ALARM]: {
    borderColor: colors.error,
  },
  [SensorStatus.DISABLED]: {
    borderColor: colors.disabledLight,
  },
  [SensorStatus.ALARM_DISABLED]: {
    borderColor: colors.disabled_alarm,
  },
  bottomContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 .25rem",
    maxHeight: 19,
    fontSize: ".9rem",
  },
  bottom: {
    default: {
      color: "#fff",
      backgroundColor: colors.disabled,
    },
    [SensorStatus.ONLINE]: {
      backgroundColor: colors.success,
    },
    [SensorStatus.OFFLINE]: {
      backgroundColor: colors.disabled,
    },
    [SensorStatus.SLEEP]: {
      backgroundColor: colors.primaryLight,
    },
    [SensorStatus.ALARM]: {
      backgroundColor: colors.error,
      textTransform: "uppercase",
    },
    [SensorStatus.DISABLED]: {
      backgroundColor: colors.disabledLight,
      color: colors.error,
      textTransform: "uppercase",
    },
    [SensorStatus.ALARM_DISABLED]: {
      backgroundColor: colors.disabled_alarm,
      color: colors.disabled_alarm_text,
      textTransform: "uppercase",
    },
  },
  icons: {
    container: {
      position: "absolute",
      left: 4,
      right: 4,
      bottom: 28,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    group: {
      height: "calc(16px + .5rem)",
      // boxShadow: '0 0 10px 5px rgba(255, 255, 255, .5)',
      backgroundColor: "rgba(255, 255, 255, .5)",
      borderRadius: "calc(8px + .25rem)",
      display: "flex",
      alignItems: "center",
      padding: "0 4px",
    },
  },
  icon: {
    width: 16,
    margin: "0 .25rem",
    // filter: 'drop-shadow(0px 0px 4px #fff) drop-shadow(0px 0px 4px #fff)',
  },
  iconBattery: {
    transform: "rotate(-90deg)",
    width: 32,
    margin: 0,
  },
  blink: {
    WebkitAnimation: "flash linear 1s infinite",
    animation: "flash linear 1s infinite",
  },
};

export default SensorPreview;
